
























import { defineComponent, reactive } from "@vue/composition-api";
import { isMobile } from "../modules/store";
import accountStateRef from "@/apps/accounts/modules/store";
import {
  AdminNavBarState,
  KonsulenNavBarState,
  NavBarState,
  ResidenNavBarState,
} from "../models/bottomNavbar";
import router from "@/router";

export default defineComponent({
  setup() {
    const navBarStateMap: Record<string, NavBarState> = {
      admin: new AdminNavBarState(),
      residen: new ResidenNavBarState(),
      konsulen: new KonsulenNavBarState(),
    };

    let navBarState = navBarStateMap.residen;
    if (accountStateRef.me.group) {
      navBarState = navBarStateMap[accountStateRef.me.group];
    }
    navBarState.updateCurrent(router.currentRoute);
    navBarState.watchRoute();

    const navBarStateRef = reactive(navBarState);
    return {
      // Data
      navBarStateRef,

      // Computed
      isMobile,
    };
  },
});

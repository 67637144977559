import {residenId} from "@/apps/accounts/modules/store";
import { Route } from "vue-router";
import { watch } from "@vue/composition-api";
import router from "@/router";

class NavItem {
  icon: string;
  label: string;
  routeName: string;
  params: Record<string, string>;
  groups: Array<string>;

  constructor(
    icon: string,
    label: string,
    routeName: string,
    params: Record<string, string> = {},
    groups: Array<string> = []
  ) {
    this.icon = icon;
    this.label = label;
    this.routeName = routeName;
    this.params = params;
    this.groups = groups;
  }
}

class NavBar {
  items: Array<NavItem>;
  urls: Array<string>;

  constructor(items: Array<NavItem>, urls: Array<string>) {
    this.items = items;
    this.urls = urls;
  }
}

// Home
const homeItem = new NavItem("home", "Home", "home");

// Pengelolaan Kasus
const kasusItem = new NavItem("briefcase-medical", "Kasus", "aktivitas-list");
const aktivitasItem = new NavItem("stethoscope", "Aktivitas", "aktivitas-list");
const perawatanItem = new NavItem(
  "briefcase-medical",
  "Perawatan",
  "perawatan-list"
);
const kegiatanItem = new NavItem("walking", "Kegiatan", "kegiatan-pendukung-list");
const pasienItem = new NavItem("address-card", "Pasien", "pasien-list");

// Penilaian
const akademisItem = new NavItem("table", "Akademis", "ujian-list");
const ujianItem = new NavItem("table", "Ujian", "ujian-list");
const karyaIlmiahItem = new NavItem(
  "table",
  "Karya Ilmiah",
  "karyailmiah-list"
);

// Program Studi
const programStudiItem = new NavItem("school", "Prog.Studi", "angkatan-list");
const anggotaItem = new NavItem("users", "Anggota", "anggota-list");
const angkatanItem = new NavItem("address-card", "Angkatan", "angkatan-list");
const pengumumanItem = new NavItem("bullhorn", "Pengumuman", "pengumuman-list");
const suratTugasItem = new NavItem("address-card", "Surat Tugas", "surat-tugas-list");

// Target
const targetItem = new NavItem("tasks", "Target", "target-diagnosis-list");
const targetDiagnosisItem = new NavItem(
  "tasks",
  "Kasus",
  "target-diagnosis-list"
);
const targetKeterampilanItem = new NavItem(
  "syringe",
  "Keterampilan",
  "target-keterampilan-list"
);

// Pencapaian
const pencapaianItem = new NavItem("star", "Pencapaian", "residen-list");
// Pencapaian Residen Detail
const paramsPencResiden = {
  id: residenId.value,
} as Record<string, string>;
const pencapaianResidenDetailItem = new NavItem(
  "star",
  "Pencapaian",
  "residen-detail",
  paramsPencResiden
);

// Informasi
const infoItem = new NavItem("bullhorn", "Info", "pengumuman-list");
const infoKonsulenItem = new NavItem("bullhorn", "Info", "pengumuman-list");

// Akun
const akunItem = new NavItem("user", "Akun", "anggota-me");

// home Navbar
const homeNavbar = new NavBar(
  Array.from([
    homeItem,
    programStudiItem,
    akademisItem,
    pencapaianItem,
    akunItem,
  ]),
  ["/home", "/anggota/residen", "/anggota/me"]
);

// programStudi Navbar
const programStudiNavbar = new NavBar(
  Array.from([homeItem, angkatanItem, anggotaItem, infoItem, targetItem]),
  ["/programstudi", "/anggota", "/informasi"]
);

// akademis Navbar
const akademisNavbar = new NavBar(
  Array.from([homeItem, ujianItem, karyaIlmiahItem]),
  ["/ujian", "/karyailmiah"]
);

// target Navbar
const targetNavbar = new NavBar(
  Array.from([homeItem, targetDiagnosisItem, targetKeterampilanItem]),
  ["/programstudi/target"]
);

// homeResiden Navbar
const homeResidenNavbar = new NavBar(
  Array.from([homeItem, kasusItem, akademisItem, infoItem, akunItem]),
  ["/home", "/anggota/me", "/informasi"]
);

// kasus Navbar
const kasusNavbar = new NavBar(
  Array.from([homeItem, aktivitasItem, perawatanItem, kegiatanItem, pasienItem]),
  ["/aktivitas", "/perawatan", "/kegiatan-pendukung", "/pasien"]
);

// akademis residen Navbar
const akademisResidenNavbar = new NavBar(
  Array.from([
    homeItem,
    ujianItem,
    karyaIlmiahItem,
    pencapaianResidenDetailItem,
  ]),
  ["/ujian", "/karyailmiah", "/anggota/residen"]
);

// homekonsulen Navbar
const homeKonsulenNavbar = new NavBar(
  Array.from([homeItem, kasusItem, akademisItem, infoKonsulenItem, akunItem]),
  ["/home", "/anggota/me"]
);

// kasusKonsulen Navbar
const kasusKonsulenNavbar = new NavBar(
  Array.from([homeItem, aktivitasItem, perawatanItem, kegiatanItem]),
  ["/aktivitas", "/perawatan", "/kegiatan-pendukung"]
);

// informasiKonsulen Navbar
const informasiNavbar = new NavBar(
  Array.from([homeItem, pengumumanItem, suratTugasItem]),
  ["/informasi", "/informasi"]
);

abstract class NavBarState {
  navBarMap: Record<string, NavBar>;
  navItemMap: Record<string, NavBar> = {};
  current: NavBar;

  constructor(navBarMap: Record<string, NavBar>) {
    this.navBarMap = navBarMap;
    this.current = navBarMap["Home"];
  }

  getCurrentNavbar(currentRoute: Route) {
    const navbarArr = Object.values(this.navBarMap);
    const specificPaths = [
      "/anggota/me",
      "/programstudi/target",
      "/anggota/residen",
    ];
    let basePath = currentRoute.path;
    const pathArr = currentRoute.path.split("/");
    if (pathArr.length > 2) {
      // check two-level
      basePath = `/${pathArr[1]}/${pathArr[2]}`;
      if (!specificPaths.includes(basePath)) {
        basePath = `/${pathArr[1]}`;
      }
    } else {
      basePath = currentRoute.path.split("/")[1];
      basePath = `/${basePath}`;
    }
    for (const navBar of navbarArr) {
      if (navBar.urls.includes(basePath)) {
        return navBar;
      }
    }
    return null;
  }

  updateCurrent(currentRoute: Route) {
    const navbar = this.getCurrentNavbar(currentRoute);
    if (navbar) {
      this.current = navbar;
    }

    if (currentRoute.name && currentRoute.name in this.navItemMap) {
      this.current = this.navItemMap[currentRoute.name];
    }
  }

  watchRoute() {
    watch(
      () => router.currentRoute,
      (currentRoute) => {
        this.updateCurrent(currentRoute);
      }
    );
  }
}

// Admin Bottom NavBar State
class AdminNavBarState extends NavBarState {
  constructor() {
    super({
      Home: homeNavbar,
      ProgramStudi: programStudiNavbar,
      Akademis: akademisNavbar,
      Target: targetNavbar,
    });

    this.navItemMap[homeItem.routeName] = this.navBarMap.Home;
    this.navItemMap[programStudiItem.routeName] = this.navBarMap.ProgramStudi;
    this.navItemMap[akademisItem.routeName] = this.navBarMap.Akademis;
    this.navItemMap[targetItem.routeName] = this.navBarMap.Target;
  }
}

// Residen Bottom NavBar State
class ResidenNavBarState extends NavBarState {
  navItemMap: Record<string, NavBar> = {};

  constructor() {
    super({
      Home: homeResidenNavbar,
      Kasus: kasusNavbar,
      Akademis: akademisResidenNavbar,
    });

    this.navItemMap[homeItem.routeName] = this.navBarMap.Home;
    this.navItemMap[kasusItem.routeName] = this.navBarMap.Kasus;
    this.navItemMap[akademisItem.routeName] = this.navBarMap.Akademis;
  }
}

// Konsulen Bottom NavBar State
class KonsulenNavBarState extends NavBarState {
  navItemMap: Record<string, NavBar> = {};

  constructor() {
    super({
      Home: homeKonsulenNavbar,
      Kasus: kasusKonsulenNavbar,
      Akademis: akademisNavbar,
      Informasi: informasiNavbar
    });

    this.navItemMap[homeItem.routeName] = this.navBarMap.Home;
    this.navItemMap[kasusItem.routeName] = this.navBarMap.Kasus;
    this.navItemMap[akademisItem.routeName] = this.navBarMap.Akademis;
    this.navItemMap[pengumumanItem.routeName] = this.navBarMap.Informasi;
  }
}

export {
  AdminNavBarState,
  KonsulenNavBarState,
  NavBarState,
  NavItem,
  ResidenNavBarState,
};
